export function getCurrentDate() {
    var myDate = new Date();
    var year = myDate.getFullYear(); //年
    var month = myDate.getMonth() + 1; //月
    month = month < 10 ? "0" + month : month
    var day = myDate.getDate(); //日
    day = day < 10 ? "0" + day : day
    var days = myDate.getDay();

    var h = myDate.getHours() < 10 ? "0" + myDate.getHours() : myDate.getHours();
    var minute = myDate.getMinutes() < 10 ? "0" + myDate.getMinutes() : myDate.getMinutes();
    var s = myDate.getSeconds() < 10 ? "0" + myDate.getSeconds() : myDate.getSeconds();

    switch (days) {
        case 1:
            days = '星期一';
            break;
        case 2:
            days = '星期二';
            break;
        case 3:
            days = '星期三';
            break;
        case 4:
            days = '星期四';
            break;
        case 5:
            days = '星期五';
            break;
        case 6:
            days = '星期六';
            break;
        case 0:
            days = '星期日';
            break;
    }
    var str = year + "-" + month + "-" + day + " " + days + " " + h + ":" + minute + ":" + s;
    return str;
}

export function nowSize(val, initWidth = 1920) {
    //当前视口宽度
    let nowClientWidth = document.documentElement.clientWidth;

    return val * (nowClientWidth / initWidth);
}