import Vue from 'vue'
import Router from 'vue-router'


Vue.use(Router)

export default new Router({
    routes: [
        {
            path: '/',
            name: 'Index',
            component: () => import('@/views/index')
        },
         {
            path: '/echat',
            name: 'Echat',
            component: () => import('@/views/echat')
        },
        {
            path: '/baoGong',
            name: 'BaoGong',
            component: () => import('@/views/baoGong')
        },
        {
            path: '/changGuiXian',
            name: 'ChangGuiXian',
            component: () => import('@/views/changGuiXian/index.vue')
        }
    ]
})
