import Vue from 'vue'
import App from './App.vue'
import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';
import router from './router'


import axios from 'axios';

Vue.prototype.$axios = axios;
// var baseUrl = 'http://127.0.0.1:3001'
var baseUrl = 'http://122.225.64.11:3001'
axios.defaults.baseURL = baseUrl
Vue.prototype.$baseUrl = baseUrl

import dataV from '@jiaminghi/data-view'


import '@/utils/flexible.js'


// echarts
import echarts from 'echarts'

Vue.prototype.$echarts = echarts;


import {getCurrentDate, nowSize} from '@/utils/date.js'

Vue.prototype.getCurrentDate = getCurrentDate
Vue.prototype.nowSize = nowSize


Vue.use(dataV)

Vue.config.productionTip = false
Vue.use(ElementUI);
new Vue({
    render: h => h(App), router
}).$mount('#app')
